import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Switch, Route } from 'react-router-dom';
import Home from './components/home'
import BeerDetail from './components/beer_detail'

const App = () => (
  <div className='app'>
    <Main />
   </div>
);

const Main = () => (
  <Switch>
    <Route exact path='/' component={Home}></Route>
    <Route exact path='/beer-detail' component={BeerDetail}></Route>
  </Switch>
);

export default App;
