import React from 'react';
import TagManager from 'react-gtm-module';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

const tagManagerArgs = {
  gtmId: 'GTM-TPLJWZH',
}
TagManager.initialize(tagManagerArgs)



// STARTS HERE

//import { ConfigurationOptions } from 'aws-sdk'



/*
const configuration: ConfigurationOptions = {
  secretAccessKey: 'a48oSofE1CNRw49beXY8Ctn7KxIbpgjVkdozMzFr',
  accessKeyId: 'AKIAUGPADABSMPGDE3W2'
}

AWS.config.update(configuration)
*/
// ENDS HERE

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));
