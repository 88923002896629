import React from "react";
import "./beer_detail.css"

const BeerDetailPage = () => {
    const beerDetail = (
        <div>
            <header>
                <div className="tworow">
                    <div className="tworowinner"></div>
                    <div className="tworowinner"></div>
                </div>
                <div className="carousel-inner" role="listbox">
                    <div className="drop-container">
                        <div className="drop">
                            <img className="img-brew" alt="mudde_brewery_logo "src="img/Logo Mudde Brewery_pink_v2.png"></img>
                        </div>
                    </div>
                </div>
                <span className="scroll-btn">
                    <a href="#">
                        <span className="mouse">
                            <span className="scroll-btn scroll-span"></span>
                        </span>
                    </a>
                    <p className="scroll-btn scroll-text">test</p>
                </span>
            </header>
        </div>
    ) 
    return (beerDetail)
}

const BeerDetail = () => {
    return (<BeerDetailPage/>)
}

export default BeerDetail
